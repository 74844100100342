module.exports = {
  comm: {
    home: 'होम',
    pricing: 'मूल्य निर्धारण',
    blog: 'आवाज क्लोनिंग और API',
    language: 'भाषा:',
    select_language: 'भाषा चुनें',
    txt2voice: 'पाठ से वाक्',
    voice2txt: 'वाक् से पाठ',
    voiceclone: 'वॉयस क्लोनिंग',
    video2txt: 'वीडियो से पाठ',
    footer_help: 'मदद की ज़रूरत है? हमें मेल करें:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'लॉग इन करें',
    login_desc: 'अपने TikTok वॉइस जनरेटर खाते तक पहुंचने के लिए लॉगिन करें',
    logout: 'लॉग आउट करें',
  },
  txt2voice: {
    title: 'TikTok वॉइस जेनरेटर: मजेदार TikTok AI आवाजें बनाएं',
    description: 'मजेदार TikTok AI आवाजें मुफ्त में बनाएं और डाउनलोड करें जैसे jessie आवाज, C3PO आवाज, ghostface आवाज...',
    keywords: 'TikTok वॉइस जेनरेटर,TikTok AI वॉइस,Tik Tok वॉइस जेनरेटर',
    main_title_p01: 'TikTok वॉइस जेनरेटर',
    main_title_p02: 'मजेदार TikTok आवाजें बनाएं: jessie आवाज, C3PO आवाज, ghostface आवाज',
    main_textarea_holder: 'यहां टेक्स्ट टाइप या पेस्ट करें',
    main_genvoice: 'बनाएं',
    main_generating: 'बन रहा है',
    main_input_empty: 'कृपया टेक्स्ट टाइप या पेस्ट करें',
    daily_usage_limit_msg: '1) आज की अधिकतम उपयोग सीमा पूरी हो चुकी है। कृपया कल फिर से प्रयास करें। 2) यदि आपको तत्काल अधिक उपयोग की आवश्यकता है, तो कृपया हमें ईमेल करें।',
    text_max_prompt1: 'कृपया शब्दों की संख्या को सीमित करें',
    text_max_prompt2: 'शब्द या कम!',
    popup_nologin_title: 'अपनी सदस्यता योजना अपग्रेड करें',
    popup_nologin_desc: 'प्रीमियम सदस्यता पर अपग्रेड करके अधिक आवाज़ जनरेशन समय अनलॉक करें।',
    popup_nologin_btntext: 'सदस्यता योजनाएं देखें',
    popup_nosub_title: 'अपनी सदस्यता योजना अपग्रेड करें',
    popup_nosub_desc: 'प्रीमियम सदस्यता पर अपग्रेड करके अधिक आवाज़ जनरेशन समय अनलॉक करें।',
    popup_nosub_btntext: 'सदस्यता योजनाएं देखें',
    popup_sublimit_title: 'सदस्यता समाप्त हो गई है, कृपया अपग्रेड करें',
    popup_sublimit_desc: 'हमारी प्रीमियम सुविधाओं और असीमित पहुंच के साथ अपने अनुभव को बेहतर बनाएं।',
    popup_sublimit_btntext: 'और खरीदने के लिए जाएं',
  },
  pricing: {
    new_features_alert: "📣 हमने भुगतान किए गए सदस्यों के लिए 🎙️ आवाज क्लोनिंग और 🤖 API सेवाएं शुरू की हैं! (भुगतान के बाद, इन सुविधाओं के लिए सहायता हेतु tiktokaivoicetool@gmail.com पर ईमेल करें)। इसके अतिरिक्त, किसी भी अनुकूलन अनुरोध के लिए हमें ईमेल करने में संकोच न करें😃",
    pricing_title: 'व्यक्तिगत निर्माताओं से लेकर सबसे बड़ी कंपनियों तक, हमारे पास आपके लिए एक योजना है।',
    pricing_desc: '100+ प्राकृतिक, मानव जैसी आवाजें। 15+ भाषाएँ। और लगभग सभी के लिए सबसे कम कीमत',
    sub_free_name: 'मुफ्त',
    sub_free_desc: 'उन व्यक्तियों के लिए जो अत्याधुनिक AI ऑडियो का प्रयास करना चाहते हैं',
    sub_free_content: [
      'प्रति माह 1000 अक्षरों की सीमा',
      'प्रति दिन 105नरेशन',
    ],
    sub_starter_name: 'स्टार्टर',
    sub_starter_desc: 'उन निर्माताओं के लिए जो वैश्विक दर्शकों के लिए प्रीमियम सामग्री का उत्पादन करते हैं',
    sub_starter_content: [
      '1 आवाज क्लोन',
      'प्रति माह 300,000 अक्षरों की सीमा (लगभग 7 घंटे ऑडियो)',
      'असीमित डाउनलोड',
      '72 घंटे के भीतर ईमेल सपोर्ट',
      'नई आवाजों और सुविधाओं तक प्राथमिकता पहुंच',
    ],
    sub_pro_name: 'प्रो',
    sub_pro_desc: 'उन निर्माताओं के लिए जो अपने सामग्री उत्पादन का विस्तार कर रहे हैं',
    sub_pro_content: [
      'API पहुंच',
      '3 आवाज क्लोन',
      'प्रति माह 1,000,000 अक्षरों की सीमा (लगभग 24 घंटे ऑडियो)',
      'असीमित डाउनलोड',
      '48 घंटे के भीतर ईमेल सपोर्ट',
      'नई आवाजों और सुविधाओं तक प्राथमिकता पहुंच',
    ],
    period_year: 'वार्षिक',
    period_month: 'मासिक',
    period_month_short: 'माह',
    billed_year: 'वार्षिक रूप से बिल किया गया',
    most_popular: 'सबसे लोकप्रिय',
    discount_quantity: '$48 बचाएं',
    buy_btntext: 'शुरू करें',
    pay_succ: 'भुगतान सफल!',
    pay_succ_desc: 'आपकी खरीदारी के लिए धन्यवाद। लेनदेन सफलतापूर्वक संसाधित किया गया।',
    pay_succ_btntext: 'TikTok वॉइस का उपयोग शुरू करें',
    pay_fail: 'भुगतान विफल!',
    pay_fail_desc: "क्षमा करें, हम आपका लेनदेन संसाधित नहीं कर सके। कृपया पुनः प्रयास करें या हमारी सहायता टीम से संपर्क करें (tiktokaivoicetool@gmail.com)।",
    pay_fail_btntext: 'होम पेज पर वापस जाएं',
  },
  setting: {
    setting: 'सेटिंग्स',
    setting_title: 'यहां अपना खाता, उपयोग और सदस्यता प्रबंधित करें।',
    basic_info: 'बुनियादी जानकारी',
    user_name: 'नाम',
    user_email: 'ईमेल',
    user_account: 'खाता',
    user_subscript: 'वर्तमान योजना',
    user_usage: 'उपयोग',
    manage_subscript: 'सदस्यता प्रबंधित करें',
    use_limit_promote: "आपने अपनी मासिक 10,000 अक्षर सीमा में से 5,000 अक्षरों का उपयोग कर लिया है।",
    not_logged_in: 'आप लॉग इन नहीं हैं',
    go_to_login: 'लॉगिन पर जाएं >>',
  },
  login: {
    title: "TikTok वॉइस जनरेटर",
    subtitle: "अपने TikTok वॉइस जनरेटर खाते तक पहुंचने के लिए लॉगिन करें",
    alreadyLoggedIn: "आप पहले से ही लॉग इन हैं",
    goToHome: "होम पेज पर जाएं"
  },
  faq: {
    quest1: 'TikTok वॉइस जेनरेटर - सबसे अच्छा TikTok AI वॉइस टूल',
    answer1_1: 'यह नवीनतम tiktok tts तकनीक द्वारा विकसित tiktok AI वॉइस जेनरेटर टूल है, यह tiktok आवाजें बना सकता है जैसे jessie आवाज (महिला आवाज), siri आवाज, ghostface आवाज, C3PO आवाज (रोबोट आवाज), डीप आवाज (कहानी कहने वाला), skye आवाज, गर्म आवाज, bestie आवाज, हीरो आवाज (chirisvoice), सहानुभूतिपूर्ण आवाज, गंभीर आवाज, joey आवाज, stitch आवाज, Stormtrooper आवाज (स्टार वार्स), Rocket आवाज (गार्डियंस ऑफ द गैलेक्सी)।',
    answer1_2: 'जल्द ही समर्थित होने वाली tiktok AI आवाजें शामिल हैं: derek आवाज, trickster आवाज, austin butler आवाज, समाचार रिपोर्टर आवाज, adam आवाज, कुत्ता आवाज, miley cyrus आवाज, alexa आवाज, एलियन आवाज, पशु आवाज, बेबी आवाज, कंप्यूटर आवाज, chipmunk आवाज, echo आवाज, npr आवाज।',
    answer1_3: 'इसके अतिरिक्त, tiktok वॉइस जेनरेटर टूल कई अन्य भाषाओं का समर्थन करता है, जिनमें चीनी, जापानी, कोरियाई, वियतनामी, थाई, हिंदी, फ़ारसी, रूसी, जर्मन, फ्रेंच, रोमानियाई, चेक, स्पेनिश, पुर्तगाली, बंगाली, इतालवी, अरबी, उर्दू, चीनी पारंपरिक, और मलय शामिल हैं।',
    answer1_4: 'यदि आपको किसी विशेष आवाज़ की तत्काल आवश्यकता है, तो कृपया मुझे ईमेल करें।',

quest2: 'TikTok वॉइस जेनरेटर के फायदे क्या हैं?',
    answer2_1: '- TikTok वॉइस जेनरेटर टूल विभिन्न प्रकार की आवाजें बना सकता है, जो अक्सर TikTok वीडियो में उपयोग होती हैं।',
    answer2_2: '- यह नवीनतम AI वॉइस जेनरेटर है जो मानव जैसी tiktok tts आवाजें बना सकता है।',
    answer2_3: '- यह पीसी पर वीडियो संपादन के लिए अधिक सुविधाजनक है।',
    answer2_4: '- आप कुछ आवाजों का उपयोग कर सकते हैं जो वर्तमान में TikTok APP tts में नहीं मिलतीं।',

quest3: 'TikTok वॉइस जेनरेटर का उपयोग कैसे करें?',
    answer3_1: 'TikTok वॉइस जेनरेटर का उपयोग करना बहुत आसान है:',
    answer3_2: '- भाषा और आवाज़ का उच्चारण चुनें।',
    answer3_3: '- वाक् में बदलने के लिए टेक्स्ट इनपुट बॉक्स में टाइप करें।',
    answer3_4: '- जनरेट बटन दबाएं और कुछ सेकंड प्रतीक्षा करें।',
    answer3_5: '- TikTok AI आवाज़ प्ले करें या डाउनलोड करें।',

quest4: 'सबसे प्रसिद्ध पुरुष tiktok आवाज़ क्या हैं?',
    answer4_1: 'सबसे प्रसिद्ध पुरुष tiktok आवाज़ डीप आवाज़ है, जिसे आधिकारिक तौर पर कहानी कहने वाला कहा जाता है।',
    answer4_2: 'सबसे मजेदार tiktok आवाज़ के लिए, मैं व्यक्तिगत रूप से ghostface आवाज़ और C3PO आवाज़ की सलाह देता हूं। ये मजेदार TikTok वीडियो के लिए डबिंग के लिए अक्सर उपयोग की जाती हैं क्योंकि ये आसानी से पहचानी जाती हैं और तेजी से दर्शकों को खींच सकती हैं।',

quest5: 'सबसे कष्टप्रद TikTok आवाज़ें क्या हैं?',
    answer5_1: 'मुझे विश्वास है कि वास्तव में कोई "कष्टप्रद" tiktok आवाज़ नहीं है।',
    answer5_2: 'यह अधिकतर उस संदर्भ और सामग्री के बारे में है जहां आवाज़ का उपयोग किया जाता है जो कुछ लोगों को असुविधाजनक बना सकती है। उदाहरण के लिए, कुछ लोग jessie आवाज़ को कष्टप्रद पाते हैं क्योंकि यह एक निश्चित अवधि के दौरान TikTok पर बहुत बार प्रकट हुई। हालांकि, इसके स्वर और गुणवत्ता के कारण, यह वास्तव में एक शानदार tiktok आवाज़ प्रभाव है। इसके अलावा, इसके पीे का tiktok आवाज़ अभिनेता बहुत पेशेवर है और वह खुद भी TikTok पर वीडियो पोस्ट करती हैं।',
    answer5_3: 'इसलिए, मैं सुझाव देता हूं कि हर कोई कुछ चिंताओं और पूर्वाग्रहों को छोड़ दे, इस टूल से आवाज़ें कई बार सुनें, और धैर्यपूर्वक आपके लिए सबसे अच्छा tiktok आवाज़ प्रभाव खोजें।',

quest6: 'TikTok वॉइस जेनरेटर का उपयोग करके jessie आवाज़ कैसे बनाएं?',
    answer6_1: '- TikTok वॉइस जेनरेटर टूल के पहले ड्रॉपडाउन मेनू में, English (US) चुनें, फिर दूसरे ड्रॉपडाउन मेनू में, Jessie आवाज़ (महिला आवाज़) चुनें।',
    answer6_2: '- टेक्स्ट इनपुट बॉक्स में टाइप करें और जनरेट बटन क्लिक करें।',
    answer6_3: '- कुछ सेकंड या अधिकतम दस सेकंड प्रतीक्षा करें, आप AI आवाज़ सुनेंगे। ऑपरेशन बार में, नीचे की ओर तीर वाले बटन को खोजें और डाउनलोड करने के लिए क्लिक करें।',

quest7: 'TikTok वॉइस जेनरेटर का उपयोग करके C3PO आवाज़ कैसे बनाएं?',
    answer7_1: '- TikTok वॉइस जेनरेटर टूल के पहले ड्रॉपडाउन मेनू में, English (US) चुनें, फिर दूसरे ड्रॉपडाउन मेनू में, C3PO आवाज़ (रोबोट आवाज़) चुनें।',
    answer7_2: '- टेक्स्ट इनपुट बॉक्स में टाइप करें और जनरेट बटन क्लिक करें।',
    answer7_3: '- कुछ सेकंड या अधिकतम दस सेकंड प्रतीक्षा करें, आप AI आवाज़ सुनेंगे। ऑपरेशन बार में, नीचे की ओर तीर वाले बटन को खोजें और डाउनलोड करने के लिए क्लिक करें।',

quest8: 'TikTok वॉइस जेनरेटर का उपयोग करके ghostface आवाज़ कैसे बनाएं?',
    answer8_1: '- TikTok वॉइस जेनरेटर टूल के पहले ड्रॉपडाउन मेनू में, English (US) चुनें, फिर दूसरे ड्रॉपडाउन मेनू में, ghostface आवाज़ चुनें।',
    answer8_2: '- टेक्स्ट इनपुट बॉक्स में टाइप करें और जनरेट बटन क्लिक करें।',
    answer8_3: '- कुछ सेकंड या अधिकतम दस सेकंड प्रतीक्षा करें, आप AI आवाज़ सुनेंगे। ऑपरेशन बार में, नीचे की ओर तीर व���ले बटन को खोजें और डाउनलोड करने के लिए क्लिक करें।',

quest9: 'TikTok वॉइस जेनरेटर का उपयोग करके siri आवाज़ कैसे बनाएं?',
    answer9_1: '- TikTok वॉइस जेनरेटर टूल के पहले ड्रॉपडाउन मेनू में, English (US) चुनें, फिर दूसरे ड्रॉपडाउन मेनू में, siri आवाज़ चुनें।',
    answer9_2: '- टेक्स्ट इनपुट बॉक्स में टाइप करें और जनरेट बटन क्लिक करें।',
    answer9_3: '- कुछ सेकंड या अधिकतम दस सेकंड प्रतीक्षा करें, आप AI आवाज़ सुनेंगे। ऑपरेशन बार में, नीचे की ओर तीर वाले बटन को खोजें और डाउनलोड करने के लिए क्लिक करें।',

quest10: 'TikTok वीडियो में tiktok आवाज़ प्रभाव कैसे जोड़ें?',
    answer10_1: 'यदि आप जानना चाहते हैं कि TikTok पर आधिकारिक tiktok आवाज़ों का उपयोग कैसे करें, तो मैं जल्द ही इस विषय पर एक समर्पित ब्लॉग पोस्ट लिखूंगा।',
    answer10_2: 'यहाँ, मैं समझाऊंगा कि TikTok Voice Generator से आवाज़ बनाकर और डाउनलोड करके उसे TikTok पर कैसे प्रकाशित करें।',
    answer10_3: '1. यदि आपने पीसी पर tiktok आवाज़ बनाई है और उसे TikTok या अपने फोन पर किसी अन्य वीडियो एडिटिंग ऐप पर अपलोड करना चाहते हैं, तो आपको आवाज़ फ़ाइल को अपने फोन में स्थानांतरित करना होगा। iPhones के लिए, आप airDrop का उपयोग करके स्थानांतरित कर सकते हैं। Android फोन के लिए, मैं इस प्रक्रिया से बहुत परिचित नहीं हूं, लेकिन आप निश्चित रूप से इसे करने के तरीके और उपकरण पाएंगे।',
    answer10_4: '2. जब आवाज़ फ़ाइल आपके फोन में स्थानांतरित हो जाए, तो आप TikTok खोल सकते हैं:',
    answer10_5: '- इंटरफ़ेस के नीचे "+" बटन को टैप करें, अपने फोन में वीडियो चुनें।',
    answer10_6: '- वीडियो अपलोड करने के बाद, स्क्रीन के दाईं ओर एक चौकोर आइकन खोजें और एडिटिंग पेज में प्रवेश करें।',
    answer10_7: '- पेज के नीचे "वॉयस जोड़ें" बटन खोजें और अभी-अभी स्थानांतरित की गई आवाज़ फ़ाइल का चयन करने के लिए क्लिक करें।',
    answer10_8: '- इस इंटरफ़ेस में, आप वीडियो में कुछ समायोजन कर सकते हैं, और फिर इसे प्रकाशित करने के लिए ऊपर दाईं ओर बटन क्लिक करें।',

quest11: 'क्या TikTok वॉइस मुफ्त है?',
    answer11: 'हाँ, यह एक मुफ्त tiktok AI वॉइस टेक्स्ट टू स्पीच जेनरेटर टूल है।',

quest12: 'मैं अपने TikTok वीडियो में लोकप्रिय TikTok आवाज़ें कहां बना सकता हूँ?',
    answer12: 'आप https://tiktokvoice.net/ पर जाकर tiktok AI आवाज़ों का उपयोग करके अपने वीडियो में जोड़ सकते हैं।'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}